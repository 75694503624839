
	frappe.templates['healthcare_orders'] = `<div class="orders">
	<div class="new-btn pb-3">
		{% if (create_orders) { %}
		<span>
			<button class="btn btn-sm small new-service-request-btn mr-1">
				<svg class="icon icon-sm">
					<use href="#icon-add"></use>
				</svg>
				{{ __("New Service Request") }}
			</button>
			<button class="btn btn-sm small new-medication-btn">
				<svg class="icon icon-sm">
					<use href="#icon-add"></use>
				</svg>
				{{ __("New Medication Request") }}
			</button>
		</span>
		{% } %}
	</div>
	<div class="section-body">
		<div class="service-requests pr-1">
			<div class="open-section-head">
				<span class="ml-2">{{ __("Service Requests") }}</span>
			</div>
			{% if (service_requests && service_requests.length) { %}
				{% for(var i=0, l=service_requests.length; i<l; i++) { %}
					<div class="service-request" name="{{ service_requests[i].name }}">
						<div class="flex justify-between">
							<div class="row label-area font-md ml-1">
								<span class="mr-2">
									<svg class="icon icon-sm">
										<use href="#icon-menu"></use>
									</svg>
								</span>
								<a href="/app/service-request/{{ service_requests[i].name }}" title="{{ __('Service Requests') }}">
									{%= service_requests[i].name %}
								</a> - <span style="font-size: 10px;">{{service_requests[i].order_group}}</span>
							</div>
							<div class="colo-sm-2" style="float:right; padding-right:10px; padding-top:10px; padding-bottom:10px;">
								{% if(service_requests[i].billing_status=="Invoiced") { %}
									<div class="invoiced">
										{{ __("Invoiced") }}
									</div>
								{% } %}
								<button data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" class="btn btn-xs btn-secondary order-btn">
									{%= status_code_map[service_requests[i].status] %}
								</button>
								<ul class="dropdown-menu dropdown-menu-right"
									style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-172px, 26px, 0px);"
									x-placement="bottom-end">
									{% if(service_requests[i].status == "Active") { %}
									<li><a class="dropdown-item service-request-onhold" data-action="on-hold">{{ __("On Hold") }}</a></li>
									<li><a class="dropdown-item service-request-replace" data-action="replace">{{ __("Replace") }}</a></li>
									{% } else if (service_requests[i].status == "On Hold") { %}
									<li><a class="dropdown-item service-request-active" data-action="active">{{ __("Active") }}</a></li>
									{% } %}
									{% if(service_requests[i].docstatus == 1 && service_requests[i].status == "Active") { %}
									<li><a class="dropdown-item order-cancel" data-action="cancel">{{ __("Cancel") }}</a></li>
									{% } %}
								</ul>
								</div>
						</div>
						<div class="service-request-details row" style="width: 100%;">
							<div class="mb-2 head col-xs-3">
								<div class="row">
									<div class="col-xs-2">
										{{ frappe.avatar(service_requests[i].practitioner_email) }}
									</div>
									<div class="col-xs-10">
										<div class="time small text-muted">
											{{ frappe.datetime.global_date_format(service_requests[i].order_date) }} {{ service_requests[i].order_time }}<br>
											{{ service_requests[i].practitioner_name }}
										</div>
									</div>
								</div>
							</div>
							 <div style="padding-bottom:20px; padding-left:30px; padding-right:10px;">
								{% if(service_requests[i].template_dt) { %}
									<div class="text-muted  ml-1">
										{{ __("Order Type:") }}
										<b>{%= service_requests[i].template_dt %}</b>
									</div>
								{% } %}
								{% if(service_requests[i].template_dn) { %}
									<div class="text-muted ml-1">
										{{ __("Order Name:") }}
										<b>{%= service_requests[i].template_dn %}</b>
									</div>
								{% } %}
							 </div>
						</div>
						{% if(service_requests[i].lab_details) { %}
						<div class="text-muted lab-details">
							{%= service_requests[i].lab_details %}
						</div>
					{% } %}
				</div>
			    {% } %}
            {% } else { %}
			<div class="no-orders text-muted">
                    {{ __("No Service Requests") }}
                </div>
		    {% } %}
		</div>
		<div class="medication-requests pr-1">
			<div class="open-section-head">
				<span class="ml-2">{{ __("Medication Requests") }}</span>
			</div>
			{% if (medication_requests && medication_requests.length) { %}
				{% for(var i=0, l=medication_requests.length; i<l; i++) { %}
					<div class="medication-request" name="{{ medication_requests[i].name }}">
						<div class="flex justify-between">
							<div class="row label-area font-md ml-1">
								<span class="mr-2">
									<svg class="icon icon-sm">
										<use href="#icon-menu"></use>
									</svg>
								</span>
								<a href="/app/medication-request/{{ medication_requests[i].name }}" title="{{ __('Medication Requests') }}">
									{%= medication_requests[i].name %}
								</a> - <span style="font-size: 10px;">{{medication_requests[i].order_group}}</span>
							</div>
							<div class="colo-sm-2" style="float:right; padding-right:10px; padding-top:10px; padding-bottom:10px;">
								{% if(medication_requests[i].billing_status=="Invoiced") { %}
									<div class= "invoiced" style="float: left; padding-right: 10px;">
										{{ __("Invoiced") }}
									</div>
								{% } %}
								<button data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" class="btn btn-xs btn-secondary order-btn">
									{%= status_code_map[service_requests[i].status] %}
									</button>
									<ul class="dropdown-menu dropdown-menu-right"
									style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-172px, 26px, 0px);"
									x-placement="bottom-end">
									{% if(medication_requests[i].status == "Active") { %}
									<li><a class="dropdown-item service-request-onhold" data-action="on-hold">{{ __("On Hold") }}</a></li>
									<li><a class="dropdown-item service-request-replace" data-action="replace">{{ __("Replace") }}</a></li>
									{% } else if (medication_requests[i].status == "On Hold") { %}
									<li><a class="dropdown-item service-request-active" data-action="active">{{ __("Active") }}</a></li>
									{% } %}
									{% if(medication_requests[i].docstatus == 1 && medication_requests[i].status == "Active") { %}
									<li><a class="dropdown-item order-cancel" data-action="cancel">{{ __("Cancel") }}</a></li>
									{% } %}
								</ul>
								</div>
						</div>
						<div class="medication-request-details row" style="width: 100%;">
							<div class="mb-2 head col-xs-3">
								<div class="row">
									<div class="col-xs-2">
										{{ frappe.avatar(medication_requests[i].practitioner_email) }}
									</div>
									<div class="col-xs-10">
										<div class="time small text-muted">
											{{ frappe.datetime.global_date_format(medication_requests[i].order_date) }}-{{medication_requests[i].order_time}}<br>
											{{ medication_requests[i].practitioner_name }}
										</div>
									</div>
								</div>
							</div>
							<div style="padding-bottom:20px; padding-right:10px;">
								{% if(medication_requests[i].medication) { %}
									<div class="text-muted  ml-1">
										{{ __("Medication:") }}
										<b>{%= medication_requests[i].medication %}</b>
									</div>
								{% } %}
								{% if(medication_requests[i].period) { %}
								<div class="text-muted  ml-1">
									{{ __("Period:") }}
									<b>{%= medication_requests[i].period %}</b>
								</div>
								{% } %}
							 </div>
							 <div style="padding-bottom:20px; padding-left:30px; padding-right:10px;">
								{% if(medication_requests[i].dosage_form) { %}
									<div class="text-muted  ml-1">
										{{ __("Dosage Form:") }}
										<b>{%= medication_requests[i].dosage_form %}</b>
									</div>
								{% } %}
								{% if(medication_requests[i].dosage) { %}
									<div class="text-muted  ml-1">
										{{ __("Dosage:") }}
										<b>{%= medication_requests[i].dosage %}</b>
									</div>
								{% } %}
							 </div>
							 <div style="padding-bottom:20px; padding-left:30px; padding-right:10px;">
								{% if(medication_requests[i].quantity) { %}
									<div class="text-muted  ml-1">
										{{ __("Quantity:") }}
										<b>{%= medication_requests[i].quantity %}</b>
									</div>
								{% } %}
							 </div>
						</div>
				</div>
			    {% } %}
            {% } else { %}
			<div class="no-orders text-muted">
                    {{ __("No Medication Requests") }}
                </div>
		    {% } %}
		</div>
</div>


<style>
.orders {
	min-height: 50px;
	padding-left: 0px;
	padding-bottom: 15px !important;
}

.orders .new-btn {
	text-align: right;
}

.service-request, .medication-request {
	min-height: 90px;
	border: 1px solid var(--border-color);
	padding-right: 0;
	font-size: 11px;
	padding-left: 5px;
	padding-top: 10px;
}

.single-activity:last-child {
	border-bottom: 1px solid var(--border-color);
}

.checkbox {
	min-width: 22px;
}

.service-requests {
	width: 100%;
}

.service-requests:first-child {
	border-right: 0;
}

.medication-requests {
	width: 100%;
}

.open-section-head {
	background-color: var(--bg-color);
	min-height: 30px;
	border-bottom: 1px solid var(--border-color);
	padding: 10px;
	font-weight: bold;
}

.no-orders {
    text-align: center;
    padding: 30px;
}

.form-footer {
	background-color: var(--bg-color);
}

.lab-details {
	font-size: 10px;
	padding-left: 4px;
	padding-right: 12px;
	width: 100%;
}

.service-request-details, .medication-request-details {
	padding-left: 14px;
	padding-right: 14px;
}

.invoiced {
	float: left;
	padding-right: 10px;
	color:rgb(163, 160, 160)
}

</style>`;
